import React, { Component } from 'react';
import SignaturePad from 'react-signature-canvas';

import signature from '../../../../assets/img/signature.svg';
import refresh from '../../../../assets/img/refresh.svg';

import './index.scss';

// -------------------------------------------------------

class Signature extends Component {
    constructor(props) {
        super(props);

        this.sigPad = {}
    }

    clear = () => {
        const { field, unsetError, updateForm } = this.props;
        const { id } = field;

        this.sigPad.clear();

        updateForm({ target: { value: '' } }, field);
        unsetError(id);
    }
    trim = () => {
        const { field, unsetError, updateForm } = this.props;
        const { id } = field;

        const url = this.sigPad.getTrimmedCanvas().toDataURL('image/png');

        updateForm({ target: { value: url } }, field);
        unsetError(id);
    }

    render() {
        const {
            field,
            value,
            validationMessage,
            touched,
            hideField,
            styledComponents,
            error,
            cssClass,
        } = this.props;
        const {
            id,
            formId,
            type,
            label,
            isRequired,
            labelPlacement,
            width,
            customName,
        } = field;

        const { Label = 'label', Box = 'div' } = styledComponents || false;
        const style = { backgroundImage: `url(${refresh})` };

        return (
            <Box
                width={width}
                className={
                    (validationMessage && touched) || error
                        ? `form-field error ${cssClass}`
                        : `form-field ${cssClass}`
                }
                style={{ display: hideField ? 'none' : undefined }}
            >
                <div className={type}>
                    <Label htmlFor={`input_${formId}_${id}`} className={`gf-label ${labelPlacement}`}>
                        {label}
                        {isRequired ? <abbr>*</abbr> : null} 
                        <span className="example-text">Example: </span>
                        <img className="example-img" src={signature} alt="Signature Example"/>
                    </Label>
                    <React.Fragment>
                        <input 
                            id={`input_${formId}_${id}`}
                            name={customName || `input_${id}`}
                            type="hidden"
                            value={!value ? '' : value}
                        />
                        <div className="signature-container">
                            <SignaturePad
                                canvasProps={{ className: 'signature-pad' }}
                                ref={(ref) => { this.sigPad = ref }}
                                onEnd={this.trim}
                            />
                            { value &&  
                                <button className="reset-button" type="button" onClick={this.clear}>
                                    <span style={style}></span>
                                    Redo
                                </button>
                            }
                        </div>
                    </React.Fragment>
                    {((validationMessage && touched) || error) && (
                        <span className="error-message" id={`error_${formId}_${id}`}>
                            {validationMessage || error}
                        </span>
                    )}
                </div>
            </Box>
        );
    }
};

// -------------------------------------------------------

export default Signature;