import React from 'react';

// Assets.
import logo from '../../assets/img/snap-on-100-logo.svg';
import makeYourMarkLong from '../../assets/img/make-your-mark-long@2x.png';

// Styles.
import './index.scss';

// -------------------------------------------------------

function Header() {
    return (
        <header className="app-header">
            <div className="wrapper">
                <div className="app-logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className="app-tagline">
                    <img className="make-your-mark-long" src={makeYourMarkLong} alt="Make Your Mark" />
                    <div className="header-text">
                        As part of the Snap-on 100th-year celebration, you're invited to MAKE YOUR
                        MARK by answering three brief questions and adding your autograph, which
                        will become part of a commemorative display.
                    </div>
                </div>
            </div>
        </header>
    );
}

// -------------------------------------------------------

export default Header;