import React from 'react';

// Assets.
import logo from '../../assets/img/snap-on-100-logo.svg';
import makeYourMark from '../../assets/img/make-your-mark.svg';
import background from '../../assets/img/tile-background.jpg';

// Styles.
import './index.scss';

// -------------------------------------------------------

function Loader(props) {
    const { active, deactivate } = props;

    if (!active)
        return null;

    const bgStyle = {
        backgroundImage: `url(${background})`
    }

    return (
        <div className="loader">
            <div className="loader-banner-top">
                <img className="loader-main-logo" src={logo} alt="Snap-On 100"/>
            </div>
            <div className="loader-main" style={ bgStyle }>
                <img className="make-your-mark" src={makeYourMark} alt="Make Your Mark" />
            </div>
            <div className="loader-banner-bottom">
                <div className="wrapper-condensed">
                    <button className="loader-exit-button" onClick={deactivate}>
                        Get Started
                    </button>
                    <div className="banner-text wrapper-condensed">
                        As part of the Snap-on 100th-year celebration, you're invited to MAKE YOUR MARK
                        by answering three brief questions and adding your autograph, which will become
                        part of a commemorative display.
                    </div>
                </div>
            </div>
        </div >
    );
}

// -------------------------------------------------------

export default Loader;
