import React, { Component } from 'react';

// Components
import Loader from '../loader';
import Login from '../login';
import Header from '../header';
import Main from '../main';
import TermsAndConditions from '../terms-and-conditions';
import CancelFormModal from '../cancel-form-modal';

// Styles
import './index.scss';

// -------------------------------------------------------

const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaderActive: true,
      formSubmitted: false,
      formTime: null,
      count: 0,
      termsActive: false,
      cancelActive: false,
      hideForm: true,
      loggedIn: false,
    }
  }

  componentDidMount() {
    this.resetApp();

    // check for login cookie
    const cookie = getCookie('snap-login');
    if(cookie === 'they-logged-in-they-sure-did') {
      this.setState({ loggedIn: true });
    }
  }

  deactivateLoader = () => {
    this.setState({ loaderActive: false, hideForm: false, });
  }

  onSubmitSuccess = () => {
    this.setState({ formSubmitted: true, count: 5, termsActive: false });
    this.timerStart();
  }

  onTermsClick = (e) => {
    e.preventDefault(); // Stop from checking box
    this.setState({ termsActive: true });
    if (this.timer) {  clearInterval(this.timer); }
  }

  onClearClick = (e) => {
    e.preventDefault(); // Stop from doing anything unexpected
    this.setState({ cancelActive: true });
    if (this.timer) {  clearInterval(this.timer); }
  }

  onTermsExit = () => {
    this.setState({ termsActive: false });
  }

  onCancelExit = () => {
    this.setState({ cancelActive: false });
  }

  submitLogin = (password) => {
    // We don't need anything crazy secure here
    if(password === 'Snap100') {
      this.setState({ loggedIn: true });
      setCookie('snap-login', 'they-logged-in-they-sure-did', 365);
    }
  }

  timerStart = () => {
    this.timer = setInterval(() => {
      const newCount = this.state.count - 1;
      this.setState(
        { count: newCount >= 0 ? newCount : 0 }
      );
      if (newCount <= -1) {
        clearInterval(this.timer);
        this.resetApp();
      }
    }, 1000);
  }

  resetApp = () => {
    if (this.timer) {  clearInterval(this.timer); }

    this.setState(
      {
        loaderActive: true,
        formSubmitted: false,
        formTime: null,
        count: 0,
        termsActive: false,
        cancelActive: false,
        hideForm: true,
      }
    );

    setTimeout(() => {
      this.setState(
        {
          hideForm: false,
        }
      );
    }, 100)
  }

  render() {
    const { 
      state, 
      deactivateLoader, 
      onSubmitSuccess, 
      onTermsClick, 
      onTermsExit, 
      onClearClick,
      onCancelExit,
      resetApp,
      submitLogin,
    } = this;
    const { 
      cancelActive,
      loaderActive,
      formSubmitted,
      count,
      termsActive,
      hideForm,
      loggedIn,
    } = state;

    const loggedInApp = (
      <div className="app">
        <Loader active={loaderActive} deactivate={deactivateLoader} />
        <Header />
        <Main 
          count={count} 
          formSubmitted={formSubmitted} 
          hideForm={hideForm}
          onSubmitSuccess={onSubmitSuccess}
          onTermsClick={onTermsClick} 
          onClearClick={onClearClick}
        />
        <TermsAndConditions active={termsActive} deactivate={onTermsExit} />
        <CancelFormModal active={cancelActive} deactivate={onCancelExit} resetApp={resetApp} />
      </div>
    );
  
    return loggedIn ? loggedInApp : (<Login submitLogin={submitLogin} />);
  }
}

// -------------------------------------------------------

export default App;
