import React, { Component } from 'react';

// Styles.
import './index.scss';

// -------------------------------------------------------

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ password: event.target.value });
  }

  render() {
    const { submitLogin } = this.props;
    const { password } = this.state;

    return (
        <div className="login-modal">
            <div className="login-main">
                <label className="login-password-label" for="password">Password Required</label>
                <input className="login-password" type="password" name="password" value={ password } onChange={ this.handleChange }/>
                <button className="login-submit" type="submit" onClick={ () => submitLogin(password) } >Submit</button>
            </div>
        </div >
    );
  }
}

// -------------------------------------------------------

export default Login;
