import React from 'react';

// Components.
import Counter from '../counter';
import Form from '../form';

// Styles.
import './index.scss';

// -------------------------------------------------------

function Main(props) {
    const {
        count,
        formSubmitted,
        hideForm,
        onClearClick,
        onSubmitSuccess,
        onTermsClick,
    } = props;

    if(hideForm)
        return (<div className="reset"></div>);

    return (
        <main className="app-main">
            <div className="wrapper-condensed">
                { (formSubmitted) ?
                    <Counter count={count} /> :
                    <Form
                        onClearClick={onClearClick}
                        onSubmitSuccess={onSubmitSuccess}
                        onTermsClick={onTermsClick}
                    />
                }
            </div>
        </main>
    );
}

// -------------------------------------------------------

export default Main;