import React from 'react';

// Assets.
import xImg from '../../assets/img/X.svg';

// Styles.
import './index.scss';

// -------------------------------------------------------

function TermsAndConditions(props) {
    const { active, deactivate } = props;

    if (!active)
        return null;

    const style = {
        backgroundImage: `url(${xImg})`
    }

    return (
        <div className="terms-and-conditions">
            <div className="border-wrapper">
                <div className="terms-and-conditions-wrapper">
                    <div className="terms-top">
                        <h1>Publicity Waiver and Release</h1>
                        <button type="button" className="terms-exit" onClick={deactivate}>
                            <span style={style}></span>
                            Close
                        </button>
                    </div>
                    <div className="terms-and-conditions-main-outer">
                        <div className="terms-and-conditions-main">

                            <p>
                                SNAP-ON INCORPORATED, a Delaware corporation, with offices located at 2801 80th Street, Kenosha, Wisconsin 53143 ("<strong>Snap-on</strong>") desires to publicize the information you are providing within the “Make Your Mark” application, including personal data, for promotional displays, such as displaying your information within Snap-on’s facilities, during internal events, or at external trade shows and similar publicity events. 
                            </p>
                            <p>
                                In exchange for good and valuable consideration, the receipt and sufficiency of which you hereby acknowledge, you give Snap-on your permission for such use according to the terms and conditions set forth in this Publicity Waiver and Release and Privacy Policy ("<strong>Agreement</strong>").
                            </p>
                            <p>
                                You hereby irrevocably permit and license Snap-on and its affiliates and their officers, directors, and employees ("<strong>Authorized Persons</strong>"), the rights to display the data provided within the Make Your Mark application, including but not limited your personal data such as your name, biographical information, business contact information, images and likeness, and signature image (collectively “<strong>Data</strong>”) together with all materials created that incorporate any of the foregoing ("<strong>Materials</strong>") in perpetuity in any medium or format for the purpose of promoting Snap-on within its facilities and at internal and external events without further consent from or royalty, payment, or other compensation to you.
                            </p>
                            <p>
                                You acknowledge and agree that you have no right to review or approve Materials before they are used by Snap-on, and that Snap-on has no liability to you for any editing or alteration of the Materials. Any credit or other acknowledgment of you, if any, shall be determined by Snap-on in Snap-on's sole discretion. Snap-on has no obligation to create or use the Materials or to exercise any rights given by this Agreement. 
                            </p>
                            <p>
                                You hereby irrevocably waive all legal and equitable rights relating to all liabilities and claims including but not limited to claims of copyright or infringement, libel, defamation and invasion of any rights of privacy or any similar claim or cause of action in tort, contract, or any other legal theory (collectively, "<strong>Claims</strong>") arising from the Authorized Persons' exercise of their rights under this Agreement. 
                            </p>
                            <p>  
                                You represent and warrant to Snap-on that you are at least eighteen (18) years of age, and have full right, power, and authority to enter into this Agreement and grant the rights hereunder. You further represent and warrant to Snap-on that you will provide only true and correct statements and other information in connection with this Agreement. 
                            </p>
                            <h3>
                                PRIVACY POLICY
                            </h3>
                            <p>
                                By submitting the Data within the Make Your Mark application you are accepting and consenting to the practices described in this Privacy Policy.  
                            </p>
                            <p>
                                The protection of your privacy in the processing of your Data is an important concern to which we pay special attention during our business processes.  This policy sets out information related to the processing of the information you provide in the Make Your Mark application.  If we intend to make use of your Data for any new purposes which would be considered incompatible with the purposes for which it was originally collected, as set out in this Privacy Policy, we will notify you of such intended use and seek your consent to such activities.
                            </p>
                            <p>
                                Should you have any questions or concerns regarding this Privacy Policy, please contact: DataProtectionManager@snapon.com. 
                            </p>
                            <p>
                                Please read the following carefully to understand our views and practices regarding the personal data you provide in this application and how we will treat it. 
                            </p>
                            <p>
                                <strong>
                                    1. Use of the data within the Snap-on Group
                                </strong>
                            </p>
                            <p>
                                We may use the Data in the following ways:
                            </p>
                            <ul>
                                <li>
                                    Internal Business Uses: the Data may be displayed within our museum or other Snap-on facilities or distributed in internal materials that describe or depict internal events, including displays on intranet sites or other internal electronic forums.  
                                </li>
                                <li>
                                    At external events:  the Data may be displayed temporarily at external events promoting Snap-on. 
                                </li>
                            </ul>
                            <p>
                                The Data you provide may be accessed by Snap-on Incorporated and other companies within the Snap-on Incorporated group of companies and transferred to countries in the world where we do business, including the United States of America and other countries, in connection with the uses identified above and in accordance with this Privacy Policy.
                            </p>
                            <p>
                                <strong>
                                    2. Use of information by third-parties
                                </strong>
                            </p>
                            <p>
                                Subject to their compliance with applicable legislation and/or this Privacy Policy, your Data may be disclosed to service providers that are used to help develop or process Materials related to the Data consistent with the purposes outlined in this Privacy Policy.  
                            </p>
                            <p>
                                <strong>
                                    3. Information storage and minimisation
                                </strong>
                            </p>
                            <p>
                                The Materials are intended to be displayed for an indefinite period but we will endeavor not to keep the electronic storage of your personal data in a form that allows you to be identified for any longer than is reasonably necessary for achieving the permitted purposes. 
                            </p>
                            <p>
                                <strong>
                                    4. Security measures to protect personal data
                                </strong>
                            </p>
                            <p>
                                We endeavour to protect the security of your personal data. We will seek to maintain administrative, technical and physical safeguards to protect against loss, misuse or unauthorised access, disclosure, alteration or destruction of your personal data. 
                            </p>
                            <p>
                                Unfortunately, the transmission of information via the internet is not completely secure. Although we endeavour to protect your personal data, we cannot guarantee the security of your personal data transmitted to us or stored on our systems; any transmission is at your own risk. Once we have received your personal data, we will use procedures and security features to try to prevent unauthorised access. These procedures include physical, electronic, and managerial procedures. 
                            </p>
                            <p>
                                <strong>
                                    5. Your rights
                                </strong>
                            </p>
                            <p>
                                Depending on the circumstances, you may have the right to:
                            </p>
                            <ul>
                                <li>
                                    request access to any personal data we hold about you;
                                </li>
                                <li>
                                    object to the processing of your data for direct-marketing purposes;
                                </li>
                                <li>
                                    ask to have inaccurate data held about you amended or updated;
                                </li>
                                <li>
                                    ask to have your data erased or to restrict processing in certain limited situations;
                                </li>
                                <li>
                                    request the porting of your personal data to another organisation in control of your personal data; and/or
                                </li>
                                <li>
                                    object to any decision that significantly affects you being taken solely by a computer or other automated process.
                                </li>
                            </ul>
                            <p>
                                If you wish to make a formal request for information we hold about you, you can contact the Data Protection Manager at DataProtectionManager@snapon.com.
                            </p>
                            <p>
                                <strong>
                                    6. Make a complaint
                                </strong>
                            </p>
                            <p>
                                If you consider that the applicable data protection legislation or this Privacy Policy has not been followed, then you should raise the matter with our Data Protection manager at DataProtectionManager@snapon.com.  Any breach will be taken seriously. If you are a citizen of the European Union or European Economic Area, you may make a complaint at any time to the applicable supervisory authority for data protection issues.  
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

// -------------------------------------------------------

export default TermsAndConditions;
