import React from 'react';
import GravityForm from 'react-gravity-form';

// Custom Components.
import Signature from './customComponents/signature';
import Checkbox from './customComponents/checkbox';
import Text from './customComponents/text';

// Styles.
import './index.scss';

// -------------------------------------------------------

function Form(props) {
    const { 
        onClearClick,
        onSubmitSuccess,
        onTermsClick,
    } = props;

    return (
        <div className="form">
            <div className="required-reminder">* Required</div>
            <GravityForm
                backendUrl="https://snaponmakeyourmark.com/wp-json/snap-on-touch-app/v2/get-form-data"
                formID="1"
                // onChange={} // optional
                onSubmitSuccess={onSubmitSuccess} // optional - calls after form has been submitted successfully
                styledComponents={{Radiogroup: 'div'}} // optional
                customComponents={{signature: Signature, checkbox: Checkbox, text: Text}}
                customLogic={{onTermsClick: onTermsClick}}
                // populatedFields={{parameterName: "Value"}}
                // jumpToConfirmation={false} // optional, default is equal to true
            />
            <button type="button" className="clear-button" onClick={onClearClick}>
                Cancel
            </button>
        </div>
    );
}

// -------------------------------------------------------

export default Form;