import React from 'react';
import { ReactSVG } from 'react-svg'

import counterImage from '../../assets/img/counter-image.svg';
// Styles.
import './index.scss';

// -------------------------------------------------------

function Counter(props) {
    const currentCount = props.count
    return (
        <div className="counter-main">
            <div className="wrapper-condensed">
                <h1 className="counter-header">
                    <span>
                        THANK YOU FOR
                    </span>
                    MAKING<br/>
                    YOUR<br/>
                    MARK
                </h1>
                <div className={"counter-display current-count-" + currentCount}>
                    <ReactSVG src={counterImage} />
                    <div className='current-count-number'>
                        {currentCount}
                    </div>
                </div>
            </div>
        </div>
    );
}

// -------------------------------------------------------

export default Counter;