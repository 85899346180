import React from 'react';

// Styles.
import './index.scss';

// -------------------------------------------------------

function CancelFormModal(props) {
    const { active, deactivate, resetApp } = props;

    if (!active)
        return null;

    return (
        <div className="cancel-modal-wrapper">
            <div className="cancel-modal">
                <div className="cancel-modal-main">
                    Are you sure you want to cancel and erase all fields?
                </div>
                <div className="cancel-modal-button-wrapper">
                    <button 
                        type="button" 
                        className="cancel-modal-button exit-modal"
                        onClick={deactivate}
                    >
                        No, Go Back
                    </button>
                    <button 
                        type="button" 
                        className="cancel-modal-button clear-form"
                        onClick={resetApp}
                    >
                        Yes, Clear Form
                    </button>
                </div>
            </div>
        </div >
    );
}

// -------------------------------------------------------

export default CancelFormModal;
